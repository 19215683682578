var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _vm.modalActive
      ? _c(
          "div",
          { staticClass: "base-modal", class: "size-" + _vm.modalSize },
          [
            _c(
              "div",
              { staticClass: "modal-body" },
              [
                _c(
                  "div",
                  { staticClass: "close-icon", on: { click: _vm.closeModal } },
                  [_c("font-awesome-icon", { attrs: { icon: "times" } })],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "modal-header p-3" }, [
                  _c("h5", { staticClass: "modal-heading" }, [
                    _vm._v(_vm._s(_vm.modalTitle)),
                  ]),
                ]),
                _vm._v(" "),
                _c(_vm.component, { tag: "component" }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }