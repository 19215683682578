var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.saveEmailConfig()
        },
      },
    },
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-md-6 my-2" },
          [
            _c("label", { staticClass: "form-label" }, [
              _vm._v(_vm._s(_vm.$t("wizard.mail.driver"))),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
            _vm._v(" "),
            _c("base-select", {
              attrs: {
                invalid: _vm.$v.mailConfigData.mail_driver.$error,
                options: _vm.mailDrivers,
                "allow-empty": false,
                searchable: true,
                "show-labels": false,
              },
              on: { input: _vm.onChangeDriver },
              model: {
                value: _vm.mailConfigData.mail_driver,
                callback: function ($$v) {
                  _vm.$set(_vm.mailConfigData, "mail_driver", $$v)
                },
                expression: "mailConfigData.mail_driver",
              },
            }),
            _vm._v(" "),
            _vm.$v.mailConfigData.mail_driver.$error
              ? _c("div", [
                  !_vm.$v.mailConfigData.mail_driver.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tc("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row my-2" }, [
        _c(
          "div",
          { staticClass: "col-md-6 my-2" },
          [
            _c("label", { staticClass: "form-label" }, [
              _vm._v(_vm._s(_vm.$t("wizard.mail.from_mail"))),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
            _vm._v(" "),
            _c("base-input", {
              attrs: {
                invalid: _vm.$v.mailConfigData.from_mail.$error,
                type: "text",
                name: "from_mail",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.mailConfigData.from_mail.$touch()
                },
              },
              model: {
                value: _vm.mailConfigData.from_mail,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.mailConfigData,
                    "from_mail",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "mailConfigData.from_mail",
              },
            }),
            _vm._v(" "),
            _vm.$v.mailConfigData.from_mail.$error
              ? _c("div", [
                  !_vm.$v.mailConfigData.from_mail.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tc("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.$v.mailConfigData.from_mail.email
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tc("validation.email_incorrect")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-md-6 my-2" },
          [
            _c("label", { staticClass: "form-label" }, [
              _vm._v(_vm._s(_vm.$t("wizard.mail.from_name"))),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
            _vm._v(" "),
            _c("base-input", {
              attrs: {
                invalid: _vm.$v.mailConfigData.from_name.$error,
                type: "text",
                name: "name",
              },
              on: {
                input: function ($event) {
                  return _vm.$v.mailConfigData.from_name.$touch()
                },
              },
              model: {
                value: _vm.mailConfigData.from_name,
                callback: function ($$v) {
                  _vm.$set(
                    _vm.mailConfigData,
                    "from_name",
                    typeof $$v === "string" ? $$v.trim() : $$v
                  )
                },
                expression: "mailConfigData.from_name",
              },
            }),
            _vm._v(" "),
            _vm.$v.mailConfigData.from_name.$error
              ? _c("div", [
                  !_vm.$v.mailConfigData.from_name.required
                    ? _c("span", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$tc("validation.required")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "base-button",
        {
          staticClass: "pull-right mt-4",
          attrs: {
            loading: _vm.loading,
            icon: "save",
            color: "theme",
            type: "submit",
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("general.save")) + "\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }