var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.next()
          },
        },
      },
      [
        _c("p", { staticClass: "form-title" }, [
          _vm._v(_vm._s(_vm.$t("wizard.preferences"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-desc" }, [
          _vm._v(_vm._s(_vm.$t("wizard.preferences_desc"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.currency"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-select", {
                class: { error: _vm.$v.settingData.currency.$error },
                attrs: {
                  options: _vm.currencies,
                  "custom-label": _vm.currencyNameWithCode,
                  searchable: true,
                  "show-labels": false,
                  placeholder: _vm.$t("settings.currencies.select_currency"),
                  "track-by": "id",
                  label: "name",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.settingData.currency.$touch()
                  },
                },
                model: {
                  value: _vm.settingData.currency,
                  callback: function ($$v) {
                    _vm.$set(_vm.settingData, "currency", $$v)
                  },
                  expression: "settingData.currency",
                },
              }),
              _vm._v(" "),
              _vm.$v.settingData.currency.$error
                ? _c("div", [
                    !_vm.$v.settingData.currency.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.language"))),
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-select", {
                class: { error: _vm.$v.settingData.language.$error },
                attrs: {
                  options: _vm.languages,
                  searchable: true,
                  "show-labels": false,
                  placeholder: _vm.$t("settings.preferences.select_language"),
                  label: "name",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.settingData.language.$touch()
                  },
                },
                model: {
                  value: _vm.settingData.language,
                  callback: function ($$v) {
                    _vm.$set(_vm.settingData, "language", $$v)
                  },
                  expression: "settingData.language",
                },
              }),
              _vm._v(" "),
              _vm.$v.settingData.language.$error
                ? _c("div", [
                    !_vm.$v.settingData.language.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.date_format"))),
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-select", {
                class: { error: _vm.$v.settingData.dateFormat.$error },
                attrs: {
                  options: _vm.dateFormats,
                  searchable: true,
                  "show-labels": false,
                  placeholder: _vm.$t(
                    "settings.preferences.select_date_formate"
                  ),
                  label: "display_date",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.settingData.dateFormat.$touch()
                  },
                },
                model: {
                  value: _vm.settingData.dateFormat,
                  callback: function ($$v) {
                    _vm.$set(_vm.settingData, "dateFormat", $$v)
                  },
                  expression: "settingData.dateFormat",
                },
              }),
              _vm._v(" "),
              _vm.$v.settingData.dateFormat.$error
                ? _c("div", [
                    !_vm.$v.settingData.dateFormat.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.time_zone"))),
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-select", {
                class: { error: _vm.$v.settingData.timeZone.$error },
                attrs: {
                  options: _vm.timeZones,
                  searchable: true,
                  "show-labels": false,
                  placeholder: _vm.$t(
                    "settings.preferences.select_date_formate"
                  ),
                  label: "key",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.settingData.timeZone.$touch()
                  },
                },
                model: {
                  value: _vm.settingData.timeZone,
                  callback: function ($$v) {
                    _vm.$set(_vm.settingData, "timeZone", $$v)
                  },
                  expression: "settingData.timeZone",
                },
              }),
              _vm._v(" "),
              _vm.$v.settingData.timeZone.$error
                ? _c("div", [
                    !_vm.$v.settingData.timeZone.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("validation.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.fiscal_year"))),
              ]),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-select", {
                class: { error: _vm.$v.settingData.fiscalYear.$error },
                attrs: {
                  options: _vm.fiscalYears,
                  searchable: true,
                  "show-labels": false,
                  placeholder: _vm.$t(
                    "settings.preferences.select_financial_year"
                  ),
                  label: "key",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.settingData.fiscalYear.$touch()
                  },
                },
                model: {
                  value: _vm.settingData.fiscalYear,
                  callback: function ($$v) {
                    _vm.$set(_vm.settingData, "fiscalYear", $$v)
                  },
                  expression: "settingData.fiscalYear",
                },
              }),
              _vm._v(" "),
              _vm.$v.settingData.fiscalYear.$error
                ? _c("div", [
                    !_vm.$v.settingData.fiscalYear.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$tc("customers.errors.required"))),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            staticClass: "pull-right",
            attrs: {
              loading: _vm.loading,
              icon: "save",
              color: "theme",
              type: "submit",
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("wizard.save_cont")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }