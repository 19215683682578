var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "imgbox" },
    [
      _c("vue-dropzone", {
        ref: "myVueDropzone",
        attrs: {
          id: "dropzone",
          "include-styling": true,
          options: _vm.dropzoneOptions,
        },
        on: {
          "vdropzone-sending": _vm.sendingEvent,
          "vdropzone-success": _vm.successEvent,
          "vdropzone-max-files-exceeded": _vm.maximum,
          "vdropzone-file-added": _vm.getCustomeFile,
          "vdropzone-removed-file": _vm.removeFile,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }