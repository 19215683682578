var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "template-modal" }, [
    _c("div", { staticClass: "card-body" }, [
      _c(
        "div",
        { staticClass: "template-container" },
        _vm._l(_vm.modalData, function (template, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "template-img",
              class: {
                "selected-template": _vm.selectedTemplate === template.id,
              },
            },
            [
              _c("img", {
                attrs: {
                  src: template.path,
                  alt: "template-image",
                  height: "200",
                  width: "140",
                },
                on: {
                  click: function ($event) {
                    _vm.selectedTemplate = template.id
                  },
                },
              }),
              _vm._v(" "),
              _vm.selectedTemplate === template.id
                ? _c("img", {
                    staticClass: "check-icon",
                    attrs: { src: "/assets/img/tick.png" },
                  })
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-footer" },
      [
        _c(
          "base-button",
          {
            staticClass: "mr-3",
            attrs: { outline: "", color: "theme" },
            on: { click: _vm.closeEstimateModal },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "base-button",
          {
            attrs: { loading: _vm.isLoading, color: "theme" },
            on: {
              click: function ($event) {
                return _vm.chooseTemplate()
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("general.choose")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }