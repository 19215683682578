var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tax-type-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitTaxTypeData.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [
                _vm._v(_vm._s(_vm.$t("tax_types.name")) + " "),
                _c("span", { staticClass: "required" }, [_vm._v(" *")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7" },
              [
                _c("base-input", {
                  ref: "name",
                  attrs: { invalid: _vm.$v.formData.name.$error, type: "text" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.name.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.name,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "name", $$v)
                    },
                    expression: "formData.name",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.name.$error
                  ? _c("div", [
                      !_vm.$v.formData.name.required
                        ? _c(
                            "span",
                            { staticClass: "form-group__message text-danger" },
                            [_vm._v(_vm._s(_vm.$tc("validation.required")))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.formData.name.minLength
                        ? _c(
                            "span",
                            { staticClass: "form-group__message text-danger" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$tc(
                                      "validation.name_min_length",
                                      _vm.$v.formData.name.$params.minLength
                                        .min,
                                      {
                                        count:
                                          _vm.$v.formData.name.$params.minLength
                                            .min,
                                      }
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [
                _vm._v(_vm._s(_vm.$t("tax_types.percent")) + " "),
                _c("span", { staticClass: "required" }, [_vm._v(" *")]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _c(
                "div",
                { staticClass: "base-input" },
                [
                  _c(
                    "money",
                    _vm._b(
                      {
                        staticClass: "input-field",
                        class: { invalid: _vm.$v.formData.percent.$error },
                        model: {
                          value: _vm.formData.percent,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "percent", $$v)
                          },
                          expression: "formData.percent",
                        },
                      },
                      "money",
                      _vm.defaultInput,
                      false
                    )
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.formData.percent.$error
                ? _c("div", [
                    !_vm.$v.formData.percent.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$t("validation.required"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.formData.percent.between
                      ? _c(
                          "span",
                          { staticClass: "form-group__message text-danger" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("validation.enter_valid_tax_rate"))
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [_vm._v(_vm._s(_vm.$t("tax_types.description")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7" },
              [
                _c("base-text-area", {
                  attrs: { rows: "4", cols: "50" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.description.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.description,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "description", $$v)
                    },
                    expression: "formData.description",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.description.$error
                  ? _c("div", [
                      !_vm.$v.formData.description.maxLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(_vm.$t("validation.description_maxlength"))
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [_vm._v(_vm._s(_vm.$t("tax_types.compound_tax")))]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7 mr-4" },
              [
                _c("base-switch", {
                  staticClass: "btn-switch compound-tax-toggle",
                  model: {
                    value: _vm.formData.compound_tax,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "compound_tax", $$v)
                    },
                    expression: "formData.compound_tax",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-footer" },
          [
            _c(
              "base-button",
              {
                staticClass: "mr-3",
                attrs: { outline: true, color: "theme", type: "button" },
                on: { click: _vm.closeTaxModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  color: "theme",
                  icon: "save",
                  type: "submit",
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.save")
                        : _vm.$t("general.update")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }