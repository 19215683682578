var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "39",
        height: "39",
        viewBox: "0 0 39 39",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M19.22 38.44C29.8349 38.44 38.44 29.8349 38.44 19.22C38.44 8.60509 29.8349 0 19.22 0C8.60509 0 0 8.60509 0 19.22C0 29.8349 8.60509 38.44 19.22 38.44Z",
          fill: "#3B5998",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M23.442 18.5216H20.833V28.08H16.88V18.5216H15V15.1624H16.88V12.9887C16.88 11.4342 17.6184 9 20.8682 9L23.7962 9.01225V12.2729H21.6717C21.3232 12.2729 20.8332 12.447 20.8332 13.1886V15.1656H23.7874L23.442 18.5216Z",
          fill: "white",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }