var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { id: "loginForm" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { class: { "form-group": true } },
        [
          _c("base-input", {
            attrs: {
              invalid: _vm.$v.formData.email.$error,
              disabled: _vm.isSent,
              placeholder: _vm.$t("login.enter_email"),
              focus: "",
              name: "email",
            },
            on: {
              blur: function ($event) {
                return _vm.$v.formData.email.$touch()
              },
            },
            model: {
              value: _vm.formData.email,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "email", $$v)
              },
              expression: "formData.email",
            },
          }),
          _vm._v(" "),
          _vm.$v.formData.email.$error
            ? _c("div", [
                !_vm.$v.formData.email.required
                  ? _c("span", { staticClass: "help-block text-danger" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("validation.required")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.$v.formData.email.email
                  ? _c("span", { staticClass: "help-block text-danger" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("validation.email_incorrect")) +
                          "\n      "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isSent
        ? _c(
            "base-button",
            {
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                type: "submit",
                color: "theme",
              },
            },
            [
              _vm._v(
                "\n    " + _vm._s(_vm.$t("validation.send_reset_link")) + "\n  "
              ),
            ]
          )
        : _c(
            "base-button",
            {
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                color: "theme",
                type: "submit",
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("validation.not_yet")) + "\n  ")]
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "other-actions mb-4" },
        [
          _c("router-link", { attrs: { to: "/login" } }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("general.back_to_login")) + "\n    "
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }