import * as types from './mutation-types'
import Ls from '@/services/ls'

export default {
  // [types.BOOTSTRAP_COMPANIES] (state, companies) {
  //   state.companies = companies
  //   state.selectedCompany = companies[0]
  // },
  [types.BOOTSTRAP_COMPANIES] (state, company) {
    state.companies = company
    state.selectedCompany = company.id
  },
  [types.SET_SELECTED_COMPANY] (state, company) {
    // console.log('state console');
    // console.log(state);
    //console.log(company);
    //console.log('seleted company');
    Ls.set('selectedCompany', company.id)
    state.selectedCompany = company
  }
}
