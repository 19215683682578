var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "graph-container" }, [
    _c("canvas", { ref: "graph", attrs: { id: "graph" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }