var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.next()
          },
        },
      },
      [
        _c("p", { staticClass: "form-title" }, [
          _vm._v(_vm._s(_vm.$t("wizard.mail.mail_config"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-desc" }, [
          _vm._v(_vm._s(_vm.$t("wizard.mail.mail_config_desc"))),
        ]),
        _vm._v(" "),
        _c(_vm.mail_driver, {
          tag: "component",
          attrs: {
            "config-data": _vm.mailConfigData,
            loading: _vm.loading,
            "mail-drivers": _vm.mail_drivers,
          },
          on: {
            "on-change-driver": function (val) {
              return (_vm.mail_driver = _vm.mailConfigData.mail_driver = val)
            },
            "submit-data": _vm.next,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }