var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-body" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.next()
          },
        },
      },
      [
        _c("p", { staticClass: "form-title" }, [
          _vm._v(_vm._s(_vm.$t("wizard.database.database"))),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "form-desc" }, [
          _vm._v(_vm._s(_vm.$t("wizard.database.desc"))),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-5" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.database.app_url"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" * ")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.databaseData.app_url.$error,
                  type: "text",
                  name: "name",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.databaseData.app_url.$touch()
                  },
                },
                model: {
                  value: _vm.databaseData.app_url,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.databaseData,
                      "app_url",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "databaseData.app_url",
                },
              }),
              _vm._v(" "),
              _vm.$v.databaseData.app_url.$error
                ? _c("div", [
                    !_vm.$v.databaseData.app_url.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tc("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.databaseData.app_url.isUrl
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tc("validation.invalid_url")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.database.connection"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-select", {
                attrs: {
                  invalid: _vm.$v.databaseData.database_connection.$error,
                  options: _vm.connections,
                  searchable: true,
                  "show-labels": false,
                },
                on: {
                  change: function ($event) {
                    return _vm.$v.databaseData.database_connection.$touch()
                  },
                },
                model: {
                  value: _vm.databaseData.database_connection,
                  callback: function ($$v) {
                    _vm.$set(_vm.databaseData, "database_connection", $$v)
                  },
                  expression: "databaseData.database_connection",
                },
              }),
              _vm._v(" "),
              _vm.$v.databaseData.database_connection.$error
                ? _c("div", [
                    !_vm.$v.databaseData.database_connection.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tc("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.database.port"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.databaseData.database_port.$error,
                  type: "text",
                  name: "database_port",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.databaseData.database_port.$touch()
                  },
                },
                model: {
                  value: _vm.databaseData.database_port,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.databaseData,
                      "database_port",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "databaseData.database_port",
                },
              }),
              _vm._v(" "),
              _vm.$v.databaseData.database_port.$error
                ? _c("div", [
                    !_vm.$v.databaseData.database_port.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tc("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.databaseData.database_port.numeric
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tc("validation.numbers_only")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.database.db_name"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.databaseData.database_name.$error,
                  type: "text",
                  name: "database_name",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.databaseData.database_name.$touch()
                  },
                },
                model: {
                  value: _vm.databaseData.database_name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.databaseData,
                      "database_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "databaseData.database_name",
                },
              }),
              _vm._v(" "),
              _vm.$v.databaseData.database_name.$error
                ? _c("div", [
                    !_vm.$v.databaseData.database_name.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tc("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.database.username"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.databaseData.database_username.$error,
                  type: "text",
                  name: "database_username",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.databaseData.database_username.$touch()
                  },
                },
                model: {
                  value: _vm.databaseData.database_username,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.databaseData,
                      "database_username",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "databaseData.database_username",
                },
              }),
              _vm._v(" "),
              _vm.$v.databaseData.database_username.$error
                ? _c("div", [
                    !_vm.$v.databaseData.database_username.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tc("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.database.password"))),
              ]),
              _vm._v(" "),
              _c("base-input", {
                attrs: { type: "password", name: "name" },
                model: {
                  value: _vm.databaseData.database_password,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.databaseData,
                      "database_password",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "databaseData.database_password",
                },
              }),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-md-6" },
            [
              _c("label", { staticClass: "form-label" }, [
                _vm._v(_vm._s(_vm.$t("wizard.database.host"))),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "text-danger" }, [_vm._v(" *")]),
              _vm._v(" "),
              _c("base-input", {
                attrs: {
                  invalid: _vm.$v.databaseData.database_hostname.$error,
                  type: "text",
                  name: "database_hostname",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.databaseData.database_hostname.$touch()
                  },
                },
                model: {
                  value: _vm.databaseData.database_hostname,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.databaseData,
                      "database_hostname",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "databaseData.database_hostname",
                },
              }),
              _vm._v(" "),
              _vm.$v.databaseData.database_hostname.$error
                ? _c("div", [
                    !_vm.$v.databaseData.database_hostname.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$tc("validation.required")) +
                              "\n          "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "base-button",
          {
            staticClass: "pull-right mt-5",
            attrs: {
              loading: _vm.loading,
              icon: "save",
              color: "theme",
              type: "submit",
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("wizard.save_cont")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }