var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tax-row" }, [
    _c(
      "div",
      { staticClass: "d-flex align-items-center tax-select" },
      [
        _c(
          "label",
          { staticClass: "bd-highlight pr-2 mb-0", attrs: { align: "right" } },
          [_vm._v("\n      " + _vm._s(_vm.$t("estimates.tax")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "base-select",
          {
            attrs: {
              options: _vm.filteredTypes,
              "allow-empty": false,
              "show-labels": false,
              "custom-label": _vm.customLabel,
              placeholder: _vm.$t("general.select_a_tax"),
              "track-by": "name",
              label: "name",
            },
            on: {
              select: function (val) {
                return _vm.onSelectTax(val)
              },
            },
            model: {
              value: _vm.selectedTax,
              callback: function ($$v) {
                _vm.selectedTax = $$v
              },
              expression: "selectedTax",
            },
          },
          [
            _c("div", { attrs: { slot: "afterList" }, slot: "afterList" }, [
              _c(
                "button",
                {
                  staticClass: "list-add-button",
                  attrs: { type: "button" },
                  on: { click: _vm.openTaxModal },
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: "check-circle" },
                  }),
                  _vm._v(" "),
                  _c("label", [
                    _vm._v(_vm._s(_vm.$t("estimates.add_new_tax"))),
                  ]),
                ],
                1
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("br"),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "text-right tax-amount" }, [
      _c("div", {
        domProps: {
          innerHTML: _vm._s(
            _vm.$utils.formatMoney(_vm.taxAmount, _vm.currency)
          ),
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "remove-icon-wrapper" },
      [
        _vm.taxes.length && _vm.index !== _vm.taxes.length - 1
          ? _c("font-awesome-icon", {
              staticClass: "remove-icon",
              attrs: { icon: "trash-alt" },
              on: { click: _vm.removeTax },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }