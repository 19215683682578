var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section mt-2" }, [
    _c("label", { staticClass: "estimate-label" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.tax.name) +
          " (" +
          _vm._s(_vm.tax.percent) +
          "%)\n  "
      ),
    ]),
    _vm._v(" "),
    _c(
      "label",
      { staticClass: "estimate-amount" },
      [
        _c("div", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$utils.formatMoney(_vm.tax.amount, _vm.currency)
            ),
          },
        }),
        _vm._v(" "),
        _c("font-awesome-icon", {
          staticClass: "ml-2",
          attrs: { icon: "trash-alt" },
          on: {
            click: function ($event) {
              return _vm.$emit("remove", _vm.index)
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }