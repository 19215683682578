import Ls from '@/services/ls'
import * as types from './mutation-types'
import * as userTypes from '../user/mutation-types'
import * as rootTypes from '../../mutation-types'
import router from '@/router.js'






export const addUsers = ({ commit, dispatch, state }, data) => {
  console.log(data);
  
  return new Promise((resolve, reject) => {
  
    window.axios.post('/api/register_user', data).then((response) => {
      commit(types.REGISTER_USER, response.data)
      resolve(response)
    }).catch((err) => {
      reject(err)
    })
  })
}


// export const login = ({ commit, dispatch, state }, data) => {
//   let loginData = {
//     username: data.email,
//     password: data.password
//   }
//   return new Promise((resolve, reject) => {
//     axios.post('/api/auth/login', loginData).then((response) => {
//       localStorage.setItem("email",loginData.username);
//       let token = response.data.access_token
//       console.log(response.data);
//       // var PERMITTED_DOMAIN = "https://app.e2eaccounting.com/";
//       var PERMITTED_DOMAIN = "http://localhost:8000/";
// /**
//  * Receiving message from other domain
//  */
//       window.addEventListener('message', function(event) {
//           if (event.origin === PERMITTED_DOMAIN) {
//               //var msg = JSON.parse(event.data);
//               // var msgKey = Object.keys(msg)[0];
//               if (event.data) {
//                   localStorage.setItem("localstorage",token);
//               } else {
//                   localStorage.removeItem("localstorage");
//               }
//           }

//       });
//       Ls.set('auth.token', token);
      
//       // var PERMITTED_DOMAIN = "http://localhost:3000/";
//       var PERMITTED_DOMAIN = "http://localhost:8000/";
//         if (origin === PERMITTED_DOMAIN) {
//             //var msg = JSON.parse(event.data);
//             // var msgKey = Object.keys(msg)[0];
//             // if (event.data) {
//                 localStorage.setItem("localstorage",token);
//             // } else {
//             //     localStorage.removeItem("localstorage");
//             // }
//         }



//       commit('user/' + userTypes.RESET_CURRENT_USER, null, { root: true })
//       commit(rootTypes.UPDATE_APP_LOADING_STATUS, false, { root: true })

//       commit(types.AUTH_SUCCESS, token)
      
//       //window.toastr['success']('Login Successful')
//       resolve(response)
//     }).catch(err => {
//       if (err.response.data.error === 'invalid_credentials') {
//         window.toastr['error']('Invalid Credentials')
//       } else {
//         // Something happened in setting up the request that triggered an Error
//         console.log('Error', err.message)
//       }

//       commit(types.AUTH_ERROR, err.response)
//       Ls.remove('auth.token')
//       reject(err)
//     })
//   })
// }


