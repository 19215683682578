var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-prefix-input", on: { click: _vm.focusInput } },
    [
      _vm.icon
        ? _c("font-awesome-icon", {
            staticClass: "icon",
            attrs: { icon: _vm.icon },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("p", { staticClass: "prefix-label" }, [
        _c("span", { staticClass: "mr-1" }, [_vm._v(_vm._s(_vm.prefix))]),
        _vm._v("-"),
      ]),
      _vm._v(" "),
      _vm.type === "checkbox"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            ref: "basePrefixInput",
            staticClass: "prefix-input-field",
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.inputValue)
                ? _vm._i(_vm.inputValue, null) > -1
                : _vm.inputValue,
            },
            on: {
              input: _vm.handleInput,
              change: [
                function ($event) {
                  var $$a = _vm.inputValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.inputValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.inputValue = $$c
                  }
                },
                _vm.handleChange,
              ],
              keyup: _vm.handleKeyupEnter,
              keydown: _vm.handleKeyDownEnter,
              blur: _vm.handleFocusOut,
            },
          })
        : _vm.type === "radio"
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            ref: "basePrefixInput",
            staticClass: "prefix-input-field",
            attrs: { type: "radio" },
            domProps: { checked: _vm._q(_vm.inputValue, null) },
            on: {
              input: _vm.handleInput,
              change: [
                function ($event) {
                  _vm.inputValue = null
                },
                _vm.handleChange,
              ],
              keyup: _vm.handleKeyupEnter,
              keydown: _vm.handleKeyDownEnter,
              blur: _vm.handleFocusOut,
            },
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            ref: "basePrefixInput",
            staticClass: "prefix-input-field",
            attrs: { type: _vm.type },
            domProps: { value: _vm.inputValue },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputValue = $event.target.value
                },
                _vm.handleInput,
              ],
              change: _vm.handleChange,
              keyup: _vm.handleKeyupEnter,
              keydown: _vm.handleKeyDownEnter,
              blur: _vm.handleFocusOut,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }