var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "setting-main-container" }, [
    _c(
      "div",
      { staticClass: "card setting-card" },
      [
        _c(
          "div",
          { staticClass: "page-header d-flex justify-content-between" },
          [
            _c("div", [
              _c("h3", { staticClass: "page-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("settings.tax_types.title")) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "page-sub-title" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.$t("settings.tax_types.description")) +
                    "\n        "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "base-button",
              {
                staticClass: "add-new-tax",
                attrs: { outline: "", color: "theme" },
                on: { click: _vm.openTaxModal },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.tax_types.add_new_tax")) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "table-component",
          {
            ref: "table",
            staticClass: "mb-3",
            attrs: {
              "show-filter": false,
              data: _vm.taxTypes,
              "table-class": "table tax-table",
            },
          },
          [
            _c("table-column", {
              attrs: {
                sortable: true,
                label: _vm.$t("settings.tax_types.tax_name"),
                show: "name",
              },
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                sortable: true,
                filterable: true,
                label: _vm.$t("settings.tax_types.compound_tax"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.tax_types.compound_tax"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "compound-tax" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(row.compound_tax ? "Yes" : "No") +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                sortable: true,
                filterable: true,
                label: _vm.$t("settings.tax_types.percent"),
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("settings.tax_types.percent"))),
                      ]),
                      _vm._v(
                        "\n          " + _vm._s(row.percent) + " %\n        "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c("table-column", {
              attrs: {
                sortable: false,
                filterable: false,
                "cell-class": "action-dropdown",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (row) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("settings.tax_types.action"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-dropdown",
                        [
                          _c(
                            "a",
                            {
                              attrs: { slot: "activator", href: "#" },
                              slot: "activator",
                            },
                            [_c("dot-icon")],
                            1
                          ),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.EditTax(row.id)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "pencil-alt"] },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n              "
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _c("v-dropdown-item", [
                            _c(
                              "div",
                              {
                                staticClass: "dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeTax(row.id)
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "dropdown-item-icon",
                                  attrs: { icon: ["fas", "trash"] },
                                }),
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n              "
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "page-header mt-3" }, [
          _c("h3", { staticClass: "page-title" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("settings.tax_types.tax_settings")) +
                "\n      "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-box" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c("base-switch", {
                  staticClass: "btn-switch",
                  on: { change: _vm.setTax },
                  model: {
                    value: _vm.formData.tax_per_item,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "tax_per_item", $$v)
                    },
                    expression: "formData.tax_per_item",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "right ml-15" }, [
              _c("p", { staticClass: "box-title" }, [
                _vm._v(
                  "  " + _vm._s(_vm.$t("settings.tax_types.tax_per_item")) + " "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "box-desc" }, [
                _vm._v(
                  "  " +
                    _vm._s(
                      _vm.$t("settings.tax_types.tax_setting_description")
                    ) +
                    " "
                ),
              ]),
            ]),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }