var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "25",
        height: "19",
        viewBox: "0 0 25 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M21.0156 8.36719C21.7708 8.4974 22.4479 8.79688 23.0469 9.26562C23.6458 9.73438 24.1146 10.3203 24.4531 11.0234C24.8177 11.7266 25 12.4688 25 13.25C25 14.6302 24.5052 15.8151 23.5156 16.8047C22.5521 17.7682 21.3802 18.25 20 18.25H5.625C4.0625 18.25 2.73438 17.7031 1.64062 16.6094C0.546875 15.5156 0 14.1875 0 12.625C0 11.401 0.351562 10.3073 1.05469 9.34375C1.75781 8.38021 2.65625 7.70312 3.75 7.3125C3.75 7.18229 3.75 7.07812 3.75 7C3.75 5.28125 4.36198 3.8099 5.58594 2.58594C6.8099 1.36198 8.28125 0.75 10 0.75C11.1458 0.75 12.2005 1.03646 13.1641 1.60938C14.1276 2.18229 14.8828 2.9375 15.4297 3.875C16.0547 3.45833 16.7448 3.25 17.5 3.25C18.5417 3.25 19.4271 3.61458 20.1562 4.34375C20.8854 5.07292 21.25 5.95833 21.25 7C21.25 7.46875 21.1719 7.92448 21.0156 8.36719ZM15.3516 10.75C15.638 10.75 15.8333 10.6198 15.9375 10.3594C16.0417 10.099 16.0026 9.8776 15.8203 9.69531L11.6797 5.55469C11.3932 5.26823 11.1068 5.26823 10.8203 5.55469L6.67969 9.69531C6.4974 9.8776 6.45833 10.099 6.5625 10.3594C6.66667 10.6198 6.86198 10.75 7.14844 10.75H9.6875V15.125C9.6875 15.3073 9.73958 15.4635 9.84375 15.5938C9.97396 15.6979 10.1302 15.75 10.3125 15.75H12.1875C12.3698 15.75 12.513 15.6979 12.6172 15.5938C12.7474 15.4635 12.8125 15.3073 12.8125 15.125V10.75H15.3516Z",
          fill: "#B9C1D1",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }