<template>
  <footer class="site-footer">
    <input type="hidden" id="authtoken" />
    <div class="text-right">
      {{ $t('general.powered_by') }}
      <a
        href="www.e2eaccounting.com"
        target="_blank">E2E - Accounting
      </a>
    </div>
  </footer>
</template>

<script type="text/babel">
export default {
  data () {
    return {
      footer: 'footer'
    }
  },
  mounted() {
    console.log("mounted");
    if(localStorage.getItem('auth.token')){
      document.getElementById("authtoken").value=(localStorage.getItem('auth.token'));
    }else{
      document.getElementById("authtoken").value="";
    }
  }
}
</script>
