var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mail-test-modal" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.onTestMailSend.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [
                _vm._v(_vm._s(_vm.$t("general.to")) + " "),
                _c("span", { staticClass: "required" }, [_vm._v(" *")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7" },
              [
                _c("base-input", {
                  ref: "to",
                  attrs: { invalid: _vm.$v.formData.to.$error, type: "text" },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.to.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.to,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "to", $$v)
                    },
                    expression: "formData.to",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.to.$error
                  ? _c("div", [
                      !_vm.$v.formData.to.required
                        ? _c(
                            "span",
                            { staticClass: "form-group__message text-danger" },
                            [_vm._v(_vm._s(_vm.$tc("validation.required")))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.formData.to.email
                        ? _c(
                            "span",
                            { staticClass: "form-group__message text-danger" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("validation.email_incorrect")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [
                _vm._v(_vm._s(_vm.$t("general.subject")) + " "),
                _c("span", { staticClass: "required" }, [_vm._v(" *")]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-7" }, [
              _c(
                "div",
                { staticClass: "base-input" },
                [
                  _c("base-input", {
                    attrs: {
                      invalid: _vm.$v.formData.subject.$error,
                      type: "text",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.formData.subject.$touch()
                      },
                    },
                    model: {
                      value: _vm.formData.subject,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "subject", $$v)
                      },
                      expression: "formData.subject",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _vm.$v.formData.subject.$error
                ? _c("div", [
                    !_vm.$v.formData.subject.required
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.$t("validation.required"))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.$v.formData.subject.maxLength
                      ? _c("span", { staticClass: "text-danger" }, [
                          _vm._v(
                            _vm._s(_vm.$t("validation.subject_maxlength"))
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group row" }, [
            _c(
              "label",
              { staticClass: "col-sm-4 col-form-label input-label" },
              [
                _vm._v(_vm._s(_vm.$t("general.message"))),
                _c("span", { staticClass: "required" }, [_vm._v(" *")]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7" },
              [
                _c("base-text-area", {
                  attrs: {
                    invalid: _vm.$v.formData.message.$error,
                    rows: "4",
                    cols: "50",
                  },
                  on: {
                    input: function ($event) {
                      return _vm.$v.formData.message.$touch()
                    },
                  },
                  model: {
                    value: _vm.formData.message,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "message", $$v)
                    },
                    expression: "formData.message",
                  },
                }),
                _vm._v(" "),
                _vm.$v.formData.message.$error
                  ? _c("div", [
                      !_vm.$v.formData.message.required
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.$t("validation.required"))),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.$v.formData.message.maxLength
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v(
                              _vm._s(_vm.$t("validation.message_maxlength"))
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card-footer" },
          [
            _c(
              "base-button",
              {
                staticClass: "mr-3",
                attrs: { outline: true, color: "theme", type: "button" },
                on: { click: _vm.closeTaxModal },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("general.cancel")) + "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "base-button",
              {
                attrs: {
                  loading: _vm.isLoading,
                  color: "theme",
                  icon: "save",
                  type: "submit",
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      !_vm.isEdit
                        ? _vm.$t("general.save")
                        : _vm.$t("general.update")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }